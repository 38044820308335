
/* ==============
// GAME LOCK ROTATION
================*/
var isMobile = function () {
    return /(iphone|ipod|ipad|android|blackberry|windows ce|palm|symbian)/i.test(navigator.userAgent);
}
//if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
if (isMobile()) {
    console.log("Navigator: " + navigator.userAgent);
    $('.game-masak-01').addClass('to-rotate');
} else {
    $('.game-masak-01').removeClass('to-rotate');
    $('.turn-message').addClass('turn-off-message');
}

/* ==============
NAVBAR
================*/
var prevScrollpos = window.pageYOffset;
 window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-420px";
  }
  prevScrollpos = currentScrollPos;
}

// STICKY
$(window).scroll(function() {
  var scroll = $(window).scrollTop();

  if (scroll >= 50) {
    $(".sticky").addClass("nav-sticky");
  } else {
    $(".sticky").removeClass("nav-sticky");
  }
});


/*$("a[href^='#']").click(function(e) {
  e.preventDefault();
  var position = $($(this).attr("href")).offset().top;
  $("body, html").animate({
    scrollTop: position
  });
});*/

/** RATING **/
$(document).ready(function(e) {
    $("#rating-01, #rating-02, #rating-03").jRating();
});

/* ==============
MODAL FOR ALERT
================*/
$('.modal-strip').each(function(){
    if(!jQuery(this).find('.close-modal').length){
        jQuery(this).append(jQuery('<i class="fa fa-close close-modal">'));
    }
    var modal = jQuery(this);

    if(typeof modal.attr('data-cookie') != "undefined"){
       
        if(!mr_cookies.hasItem(modal.attr('data-cookie'))){
            setTimeout(function(){
                modal.addClass('reveal-modal');
            },1000);
        }
    }else{
        setTimeout(function(){
                modal.addClass('reveal-modal');
        },1000);
    }
});

$('.modal-strip .close-modal').click(function(){
    var modal = jQuery(this).closest('.modal-strip');
    if(typeof modal.attr('data-cookie') != "undefined"){
        mr_cookies.setItem(modal.attr('data-cookie'), "true", Infinity);
    }
    jQuery(this).closest('.modal-strip').removeClass('reveal-modal');
    return false;
});

/* ==============
HOME TABBING
================*/
$('.home-tabbing-nav > li > a').hover(function() {
    $(this).tab('show');
});

/* ==============
SWIPER SLIDER
================*/
var homeSlider = new Swiper('.herobanner-v3', {
    autoplay: {
        delay:4000
    },
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

/** NEWS & UPDATE HOME  **/
var newsHomeSlider = new Swiper('.news-home-slider', {
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: {
        delay:3500
    },
    loop: true,
    navigation: {
        nextEl: '.gm-nav-next',
        prevEl: '.gm-nav-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        960: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        425: {
            slidesPerView: 1,
            spaceBetween: 0,
        }
    }
});

/** TESTIMONI SLIDER **/
var testmoniSlider = new Swiper('.fieldtrip-testimoni-box', {
    autoplay: {
        delay:4000
    },
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        768: {
            spaceBetween: 40,
        }
    }
});

/** BIRTHDAY SLIDER **/
var birthdaySlider = new Swiper('.birthday-testimoni-box', {
    autoplay: {
        delay:4000
    },
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        768: {
            spaceBetween: 40,
        }
    }
});

/** MINI GAMES 01 SLIDER **/
 if(window.innerWidth < 1200) {
    new Swiper(".miniGameSlider",{
        direction: "horizontal",
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        //nextButton: ".swiper-button-next",
        //prevButton: ".swiper-button-prev",
        spaceBetween: 0,
        autoplay: {
          delay: 6000,
        },
        loop: !0
    })
} else {
    new Swiper(".miniGameSlider",{
        direction: "horizontal",
        slidesPerView: 1,
        parallax: !0,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        //nextButton: ".swiper-button-next",
        //prevButton: ".swiper-button-prev",
        spaceBetween: 0,
        speed: 2500,
        parallax: !0,
        autoplay: {
            delay: 5000,
        },
        loop: !0
    })
}

/** GAME MEMASAK STEP 2 **/
var masakStep2Slider = new Swiper('.step1-slider', {
    //autoplay: {
    //    delay:4000
    //},
    slidesPerView: 3,
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 5,
        },
        425: {
            slidesPerView: 1,
            spaceBetween: 0,
        }
    }
});

/* ===== Custom Selects
https://codepen.io/wallaceerick/pen/ctsCz
===== */
$('select').each(function(){
    var $this = $(this), numberOfOptions = $(this).children('option').length;

    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());

    var $list = $('<ul />', {
      'class': 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }

    var $listItems = $list.children('li');

    $styledSelect.click(function(e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function(){
        $(this).removeClass('active').next('ul.select-options').hide();
      });
      $(this).toggleClass('active').next('ul.select-options').toggle();
    });

    $listItems.click(function(e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      //console.log($this.val());
    });

    $(document).click(function() {
        $styledSelect.removeClass('active');
        $list.hide();
    });
});

// FORM
/*
$(document).ready(function(){
  $("#registration").validate({
    rules: {
        namaanak: "required",
        namaSekolah: "required",
        judulcerita: "required",
        urlvideo: "required",
        temastorytelling: {
        required: true,
      },
    },
    messages: {
        namaanak: {
        required: "Nama Anak harus diisi",
     },
        namaSekolah: {
        required: "Nama Sekolah harus diisi",
     },
        temastorytelling: {
        required: "Tema Storytelling harap dipilih",
     },
     judulcerita: {
        required: "Judul Cerita harus diisi",
     },
     urlvideo: {
        required: "URL Video harus diisi",
     },
    },
  });
});*/

/** LIMIT TEXT **/
(function ($) {
  $.fn.limitText = function (options) {
    var defaults = {
      length:100,
      ellipsisText:'...'
    }, options = $.extend(defaults, options);

    return this.each(
      function (i, obj) {
        if ($(obj).text().length > options.length) {
          var text = $(obj).text().substr(0, options.length);
          for (i=text.length; i > 0; i--) {
            if (text.charAt(i -1) == ' ') {
              $(obj).text(text + options.ellipsisText);
              break;
            }
            else {
              text = text.slice(0, text.length -1);
            }
          }
        }
      }
    );
  };
})(jQuery);

$(function () {
    $('.stories_title').limitText({
        length: 25,
    });
    $('.gallerystory_title').limitText({
        length: 25,
    });

    $('.stories_headline').limitText({
        length: 60,
        ellipsisText: '...'
    });
    $('.gallerystory_headline').limitText({
        length: 100,
        ellipsisText: '...'
    });
    $('.st-latest-content-title, .newsupdate-details-title, .news-related-content-title').limitText({
        length: 60,
        ellipsisText: '...'
    });
    $('.st-latest-details, .news-related-details, .minigames-01-desc').limitText({
        length: 140,
        ellipsisText: '...'
    });
});


/** SHARE **/
const button = $(".share-link"),
    item = $(".share-links li");

button.on("click", function() {
    if ($(this).hasClass("clicked")) {
        $(this).removeClass("clicked");
        item.parent().removeClass("active");
        item.parent().addClass("not-active");
    } else {
        $(this).addClass("clicked");
        item.parent().removeClass("not-active");
        item.parent().addClass("active");
    }
});


/** ISOTOPE FILTER COLOURING WITH PAGINATION **/
$(document).ready( function() {
    var itemSelector = '.grid-item'; 
    var $container = $('#cover-grid-container').isotope({
        itemSelector: itemSelector,
        masonry: {
          columnWidth: itemSelector,
          isFitWidth: true
        }
    });

    //Ascending order
    var responsiveIsotope = [
        [480, 3],
        [720, 4]
    ];

    var itemsPerPageDefault = 6;
    var itemsPerPage = defineItemsPerPage();
    var currentNumberPages = 1;
    var currentPage = 1;
    var currentFilter = '*';
    var filterAtribute = 'data-filter';
    var pageAtribute = 'data-page';
    var pagerClass = 'isotope-pager';

    function changeFilter(selector) {
        $container.isotope({
            filter: selector
        });
    }

    function goToPage(n) {
        currentPage = n;
        var selector = itemSelector;
            selector += ( currentFilter != '*' ) ? '['+filterAtribute+'="'+currentFilter+'"]' : '';
            selector += '['+pageAtribute+'="'+currentPage+'"]';
        changeFilter(selector);
    }

    function defineItemsPerPage() {
        var pages = itemsPerPageDefault;
        for( var i = 0; i < responsiveIsotope.length; i++ ) {
            if( $(window).width() <= responsiveIsotope[i][0] ) {
                pages = responsiveIsotope[i][1];
                break;
            }
        }
        return pages;
    }
    
    function setPagination() {
        var SettingsPagesOnItems = function(){
            var itemsLength = $container.children(itemSelector).length;
            var pages = Math.ceil(itemsLength / itemsPerPage);
            var item = 1;
            var page = 1;
            var selector = itemSelector;
                selector += ( currentFilter != '*' ) ? '['+filterAtribute+'="'+currentFilter+'"]' : '';
            
            $container.children(selector).each(function(){
                if( item > itemsPerPage ) {
                    page++;
                    item = 1;
                }
                $(this).attr(pageAtribute, page);
                item++;
            });
            currentNumberPages = page;
        }();

        var CreatePagers = function() {
            var $isotopePager = ( $('.'+pagerClass).length == 0 ) ? $('<div class="'+pagerClass+'"></div>') : $('.'+pagerClass);
            $isotopePager.html('');            
            for( var i = 0; i < currentNumberPages; i++ ) {
                var $pager = $('<a href="javascript:void(0);" class="pager" '+pageAtribute+'="'+(i+1)+'"></a>');
                    $pager.html(i+1);
                    
                    $pager.click(function(){
                        var page = $(this).eq(0).attr(pageAtribute);
                        goToPage(page);
                    });
                $pager.appendTo($isotopePager);
            }
            $container.after($isotopePager);
        }();
    }

    setPagination();
    goToPage(1);

    $('.filters li').click(function(){
        var filter = $(this).attr(filterAtribute);
        currentFilter = filter;
        setPagination();
        goToPage(1);
    });

    $(window).resize(function(){
        itemsPerPage = defineItemsPerPage();
        setPagination();
        goToPage(1);
    });

    $('.filter-button-group').on( 'click', 'li', function() {
        var filterValue = $(this).attr('data-filter');
        $('.filter-button-group li').removeClass('active');
        $(this).addClass('active');
    });
});




